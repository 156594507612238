import React from "react";

const BeeCirlces = ({ color }) => {
  return  <svg viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10552_35194)">
      <path d="M5.78839 11.5944C8.12498 11.5944 10.0192 9.70017 10.0192 7.36358C10.0192 5.02699 8.12498 3.13281 5.78839 3.13281C3.4518 3.13281 1.55762 5.02699 1.55762 7.36358C1.55762 9.70017 3.4518 11.5944 5.78839 11.5944Z" fill="#333333"/>
      <path d="M9.07677 9.69261C10.9885 9.69261 12.5383 8.14282 12.5383 6.23107C12.5383 4.31931 10.9885 2.76953 9.07677 2.76953C7.16502 2.76953 5.61523 4.31931 5.61523 6.23107C5.61523 8.14282 7.16502 9.69261 9.07677 9.69261Z" fill="#FFECC3" stroke="#333333" strokeWidth="0.7" strokeMiterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_10552_35194">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>;
};    

export default BeeCirlces;
